define("Automatons/nodes/2008", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2008,
    type: "node",
    attributes: {
      name: "Nuance Universal Post-Chat Survey - Portuguese - Mobile - Thank you",
      type: "thankyou",
      isOutcomeNode: 1,
      template: "template::2007"
    }
  };
});