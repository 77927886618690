define("Automatons/nodes/2005", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2005,
    type: "nodes",
    attributes: {
      name: "Nuance Universal Post-Chat Survey - Portuguese - Desktop - Initial",
      type: "survey",
      header: "Muito obrigada!",
      inputs: [{
        id: "Universal_PostChat_Question_1",
        type: "dropdown",
        isRequired: true,
        label: "Conseguimos solucionar o seu problema? <span>*</span>",
        options: ["Por favor, escolha um...", "Não", "Sim"],
        requiredErrorMesssage: "<span class='invalid-symbol'>!</span> Por favor, escolha um..."
      }, {
        id: "Universal_PostChat_Question_2",
        type: "dropdown",
        isRequired: true,
        label: "Você está satisfeito com o atendimento ao cliente realizado? <span>*</span>",
        options: ["Por favor, escolha um...", "10 - Muito Satisfeito", "9", "8", "7", "6", "5", "4", "3", "2", "1 - Muito Insatisfeito"],
        requiredErrorMesssage: "<span class='invalid-symbol'>!</span> Por favor, escolha um..."
      }, {
        id: "Universal_PostChat_Question_3",
        type: "text",
        label: "Deseja fazer mais algum comentário?",
        placeholder: "Escreva aqui..."
      }, {
        id: "Universal_PostChat_Question_4",
        type: "dropdown",
        isRequired: true,
        label: "Você recomendaria a DGO a um amigo ou familiar? <span>*</span>",
        options: ["Por favor, escolha um...", "10 - Sim, eu com certeza recomendaria a DGO", "9", "8", "7", "6", "5", "4", "3", "2", "1", "0 - Não, eu definitivamente não recomendaria a DGO"],
        requiredErrorMesssage: "<span class='invalid-symbol'>!</span> Por favor, escolha um..."
      }, {
        id: "Universal_PostChat_Question_5",
        type: "text",
        label: "Deseja fazer mais algum comentário?",
        placeholder: "Escreva aqui..."
      }],
      subheader: "Por favor, escreva a sua opinião sobre o desempenho do nosso serviço para que possamos sempre melhorar o atendimento. <br /></br /> *: Campos obrigatórios indicados com <span>*</span>",
      submitText: "ENVIAR",
      surveyAlerts: [{
        name: "Nuance Survey Portuguese Alerts",
        conditions: {
          and: [{
            or: ["Universal_PostChat_Question_1 contains N", "Universal_PostChat_Question_4 contains eu definitivamente"]
          }, "agent-group-name equals DTG_Care_POR"]
        },
        emailSpecName: "DTVLA-Email-Alert-Portuguese",
        emailSubject: "Nuance Survey Alerts",
        emailTemplate: "template::2008"
      }],
      template: "template::2001",
      transitions: {
        next: {
          target: "node::2006"
        }
      }
    }
  };
});