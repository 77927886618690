define("Automatons/automatons/2005", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2005,
    type: "automatons",
    attributes: {
      name: "Nuance Universal Post-Chat Survey - Live Agent - Desktop",
      type: "satisfactionSurvey",
      description: "CMRASI-1564",
      initialNode: "node::2009",
      style: "style::2003",
      viewport: {
        width: 500
      }
    }
  };
});