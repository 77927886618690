define("Automatons/nodes/2013", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2013,
    type: "nodes",
    attributes: {
      name: "Post-Chat VA External Link - Spanish - Initial",
      type: "survey",
      template: "template::2009"
    }
  };
});