define("Automatons/nodes/2010", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2010,
    type: "nodes",
    attributes: {
      name: "Nuance Universal Post-Chat Survey - Live Agent - Mobile",
      type: "survey",
      header: "&iexcl;Gracias por tu tiempo!",
      inputs: [{
        id: "Universal_PostChat_Question_1",
        type: "dropdown",
        isRequired: true,
        label: "&iquest;Solucionamos tu problema?<span>*</span>",
        options: ["Seleccione su opción", "No", "Si"],
        requiredErrorMesssage: "<span class='invalid-symbol'>!</span> Esta pregunta es requerida, por favor selecciona tu respuesta.",
        showText: "Si"
      }, {
        id: "Universal_PostChat_Question_2",
        type: "dropdown",
        isRequired: true,
        label: "&#191;Te encuentras satisfecho con la gestión del representante de servicio al cliente?<span>*</span>",
        options: ["Seleccione su opción", "10 - Muy satisfecho", "9", "8", "7", "6", "5", "4", "3", "2", "1 - Muy insatisfecho"],
        requiredErrorMesssage: "<span class='invalid-symbol'>!</span> Esta pregunta es requerida, por favor selecciona tu respuesta.",
        satisfactionBlock: true,
        showText: "5 - Extremadamente Satisfecho"
      }, {
        id: "Universal_PostChat_Question_3",
        type: "text",
        label: "&#191;Podrías describir los motivos por los cuales lo evaluaste de esa forma?",
        placeholder: "Escribe aqu&iacute;...",
        showText: "Si"
      }, {
        id: "Universal_PostChat_Question_4",
        type: "dropdown",
        isRequired: true,
        label: "&#191;Recomendar&iacute;as DIRECTV GO a un amigo o familiar?<span>*</span>",
        options: ["Seleccione su opción", "10 - S&iacute;, definitivamente recomendar&iacute;a DIRECTV GO", "9", "8", "7", "6", "5", "4", "3", "2", "1", "0 - No, definitivamente no recomendar&iacute;a DIRECTV GO"],
        requiredErrorMesssage: "<span class='invalid-symbol'>!</span> Esta pregunta es requerida, por favor selecciona tu respuesta.",
        satisfactionBlock: true,
        showText: "5 - Extremadamente Satisfecho"
      }, {
        id: "Universal_PostChat_Question_5",
        type: "text",
        label: "&iquest;Tienes alg&uacute;n comentario adicional?",
        placeholder: "Escribe aqu&iacute;...",
        showText: "Si"
      }],
      subheader: "Para poder ofrecerte un mejor servicio, por favor escribe tus comentarios.<br /></br /> Respuestas requeridas indicadas con <span>*</span>",
      submitText: "ENVIAR",
      surveyAlerts: [{
        name: "Nuance Survey Alerts",
        conditions: {
          and: [{
            or: ["Universal_PostChat_Question_4 contains 0 - No", "Universal_PostChat_Question_4 equals 1", "Universal_PostChat_Question_4 equals 2"]
          }, "Universal_PostChat_Question_5 is not blank"]
        },
        emailSpecName: "DTVLA-Email-Alert-1-Mobile",
        emailSubject: "Nuance Survey Alerts",
        emailTemplate: "template::2006"
      }],
      template: "template::2005",
      transitions: {
        next: {
          target: "node::2004"
        }
      }
    }
  };
});