define("Automatons/nodes/2004", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2004,
    type: "node",
    attributes: {
      name: "Nuance Universal Post Chat Thank you - Mobile",
      type: "thankyou",
      isOutcomeNode: 1,
      template: "template::2004"
    }
  };
});