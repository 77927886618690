define("Automatons/automatons/2010", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2010,
    type: "automatons",
    attributes: {
      name: "Post-Chat VA External Link - Portuguese",
      description: "CMRGEN-27126",
      initialNode: "node::2014",
      style: "style::2005"
    }
  };
});