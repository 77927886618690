define("Automatons/nodes/2014", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2014,
    type: "nodes",
    attributes: {
      name: "Post-Chat VA External Link - Portuguese - Initial",
      type: "survey",
      template: "template::2010"
    }
  };
});