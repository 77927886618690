define("Automatons/automatons/2006", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2006,
    type: "automaton",
    attributes: {
      name: "Nuance Universal Post-Chat Survey - Live Agent - Mobile",
      type: "satisfactionSurvey",
      description: "CMRASI-1564",
      initialNode: "node::2010",
      style: "style::2004"
    }
  };
});