define("Automatons/automatons/2003", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2003,
    type: "automatons",
    attributes: {
      name: "Nuance Universal Post-Chat Survey - Portuguese - Desktop",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-2345",
      initialNode: "node::2005",
      style: "style::2003",
      viewport: {
        width: 510
      }
    }
  };
});