define("Automatons/automatons/2009", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2009,
    type: "automatons",
    attributes: {
      name: "Post-Chat VA External Link - Spanish",
      description: "CMRGEN-27126",
      initialNode: "node::2013",
      style: "style::2005"
    }
  };
});