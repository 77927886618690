define("Automatons/automatons/2004", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2004,
    type: "automatons",
    attributes: {
      name: "Nuance Universal Post-Chat Survey - Portuguese - Mobile",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-2345",
      initialNode: "node::2007",
      style: "style::2004"
    }
  };
});